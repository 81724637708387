import React, { useContext,useState} from "react";
import { AppContext } from "./../contextapi/ContextApi";
import { Carousel } from "antd";
import {Link} from 'react-router-dom';
import {BsFacebook,BsTwitter,BsInstagram} from 'react-icons/bs'
import {AiFillSetting} from 'react-icons/ai'
import  i18n  from 'i18next';

const Slider = () => {
  const { sliderData,language ,toggleLanguage} = useContext(AppContext);
  const [showSetting,setShowSetting] = useState(false);
  const toggelSetting = ()=>{
    setShowSetting(!showSetting)
  };
  //handle change language
    const handleChangeLanguage = (lang)=>{
    i18n.changeLanguage(lang);
    document.documentElement.lang = lang;
    toggleLanguage(lang);
    document.documentElement.dir = i18n.dir();
    document.body.className = i18n.dir();
    window.location.reload();
  }
  return (
    <div className="slider">
      <Carousel autoplay >
       {
        sliderData&&sliderData.map((s)=>{
          return(
            <div key={s.id} style={{position:'relative'}}>
              <div className="slide-caption"
              >
              </div>
              <img src={s.slideImage} alt=""/>
            </div>
          )
        })
       }
      </Carousel>
      <div className="client-social-media">
         <ul className="list-unstyled">
          <li> <Link to="/" titel="facebook"> <BsFacebook/> </Link> </li>
          <li> <Link to="/" titel="twitter"> <BsTwitter/> </Link> </li>
          <li> <Link to="/" titel="instagram"> <BsInstagram/> </Link> </li>
         </ul>
      </div>
      <div className={`change-lang ${showSetting?'hide':''}`}>
        <div className="setting">
          <button onClick={toggelSetting}><AiFillSetting/></button>
        </div>
        <ul>
          {
            language ==="en"? <li onClick={()=>{handleChangeLanguage('ar')}}>AR</li>:
            <li onClick={()=>{handleChangeLanguage('en')}} >EN</li>
          }
        </ul>
      </div>
    </div>
  );
};

export default Slider;
