import React from "react";

const ServiceItem = ({ service }) => {
    const {icon ,name} = service;
  return (
    <div className="service-item">
        <div className="service-icon">{icon}</div>
        <div className="service-info">
            <h5 className="service-name"> {name} </h5>
        </div>
    </div>
  );
};

export default ServiceItem;
