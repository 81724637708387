import React from 'react';
import poster  from '../../asstes/images/poster.jpg';
import { useContext } from 'react';
import { AppContext } from './../../contextapi/ContextApi';

const AboutVideo = ({translate}) => {
  const {videos} = useContext(AppContext);
  return (
    <div className="about-vedios">
      <h1> {translate("HEADINGs.VIDEOHEADING")} </h1>
        <div className="container">
            <div className="row">
              {
                videos&&videos.map((v)=>{
                  return (
                  <div key={v.id} className='col-md-4'>
                    <video 
                      controls
                      width="100%" 
                      height="200px" 
                      poster={poster}
                      >
                      <source src={v.video} type="video/mp4"/>
                      <source src={v.video} type="video/ogg"/>
                      Your browser does not support HTML video.
                    </video>
                  </div>
                  )
                })
              }
            </div>
        </div>
    </div>
  )
}

export default AboutVideo