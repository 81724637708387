import React from 'react';
import {MdPlace,MdPhone,MdEmail} from 'react-icons/md';
import img1 from '../../asstes/images/footer/foot1.jpg';
import img2 from '../../asstes/images/footer/foot2.jpg';
import img3 from '../../asstes/images/footer/foot3.jpg';
import img4 from '../../asstes/images/footer/foot4.jpg'
import img5 from '../../asstes/images/footer/foot5.jpg';
import img6 from '../../asstes/images/footer/foot6.jpg';
import img7 from '../../asstes/images/footer/foot7.jpg';
import img8 from '../../asstes/images/footer/foot8.jpg'
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const {t} = useTranslation();
  return (
    <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
                <h3> {t('FOOTER.COMPANY')} </h3>
                <div className="first-col">
                  <p>  {t('FOOTER.EXPERIENS')}</p>
                  <ul>
                    <li> <MdPlace/> 
                      <span> {t('ADDRESS.STREETNAME')}</span>  
                    </li>
                    <li> <MdPhone/> <span> 0503922991 / 0553922991 </span> </li>
                    <li> <MdEmail/> <span> info@rosengineering.org </span> </li>
                  </ul>
                </div>
            </div>
            <div className="col-md-4">
              <h3> {t('NAVBER.SERVICES')}</h3>
              <div className="threed-col">
              <div className="row">
                <div className="col-md-12">
                  <p> {t('FOOTER.SITE')}</p>
                </div>
                <div className="col-md-12">
                  <p> {t('FOOTER.BUILDING')}</p>
                </div>
                <div className="col-md-12">
                  <p>{t('FOOTER.ROAD')}</p>
                 </div> 
                <div className="col-md-12">
                  <p>{t('FOOTER.RENTAL')}</p>
                </div>
              </div>
              </div>
            </div>
            <div className="col-md-4">
              <h3>{t('FOOTER.INSTAGRAM')}</h3>
              <div className="last-col">
                <ul>
                <li>
                      <img src={img1} alt="insta"/>
                  </li>
                  <li>
                      <img src={img2} alt="insta"/>
                  </li>
                  <li>
                      <img src={img3} alt="insta"/>
                  </li>
                  <li>
                      <img src={img4} alt="insta"/>
                  </li>
                  <li>
                      <img src={img5} alt="insta"/>
                  </li>
                  <li>
                      <img src={img6} alt="insta"/>
                  </li>
                  <li>
                      <img src={img7} alt="insta"/>
                  </li>
                  <li>
                      <img src={img8} alt="insta"/>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
    </footer>
  )
}

export default Footer
