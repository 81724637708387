import React from 'react'
import LayOut from './../components/layout/index';

const Services = () => {
  return (
    <LayOut>
        <div>
      Services
    </div>
    </LayOut>
  )
}

export default Services
