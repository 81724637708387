import React,{useState} from "react";
import Fancybox from "./../Fancybox";

const Gallary = ({ gallaryImages,translate }) => {
    const [gallaryCount,setGallaryCount] = useState(6)
    const gallaries = gallaryImages.slice(0,gallaryCount);
    const showMoreGallary = ()=>{
        setGallaryCount((prev=>(prev+3)));
    }
  return (
    <div className="gallary">
      <Fancybox options={{ infinite: false }}>
        <div className="row">
          {gallaries &&
            gallaries.map((g) => {
              return (
                <div key={g.id} className="col-md-6 col-lg-4">
                  <div className="gallary-img">
                    <img
                      src={g.image}
                      data-fancybox="gallery"
                      data-src={g.image}
                      className="img-fluid"
                      alt="gallary"
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </Fancybox>
      {
        gallaryCount < gallaryImages.length ?
        <button className="show-more" onClick={showMoreGallary} > {translate('BUTTONS.SHOWMORE')} </button>:null
      }
    </div>
  );
};

export default Gallary;
