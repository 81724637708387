import React,{useContext} from "react";
import LayOut from "./../components/layout/index";
import Slider from "./../components/Slider";
import AboutItem from "./../components/about/AboutItem";
import ServiceItem from './../components/sevicies/ServiceItem';
import { AppContext } from './../contextapi/ContextApi';
import Gallary from './../components/gallary/Gallary';
import CarouselList from './../components/Carousel';
import EqumentItem from './../components/equpment/EqumentItem';
import { Slide } from 'react-reveal';
import { useTranslation } from 'react-i18next';


const Home = () => {
  const {t} = useTranslation();
  const {
    services,
    gallaryImages,
    contractImages,
    equmentImages,
  } = useContext(AppContext);


  return (
    <LayOut>
      <div className="home-page">
        <section className="slider-section">
          <Slider />
        </section>
        <div className="container">
          <section className="about-section">
            <h1> {t('NAVBER.ABOUTUS')} </h1>
            <AboutItem
              translate={t}
            />
          </section>
        </div>
        <section id="services" className="services-section">
          <div className="container">
              <div className='header-section'>
                <Slide top> <h1> {t('NAVBER.SERVICES')} </h1> </Slide>
                <Slide right>
                <p> {t('SERVICES.DESCRIPTION')} </p>
                </Slide>
              
              </div>
              <div className="row my-5">
                {
                  services&&services.map((service)=>{
                    return <div key={service.id} className="col-md-3">
                      <ServiceItem key={service.id} service={service} />
                      </div>
                  })
                }
              </div>
            </div>
        </section>
        <section className="gallary-section">
          <div className="container">
            <Slide top> <h1> {t('HEADINGs.GALLARY')} </h1> </Slide>
            <Gallary gallaryImages = {gallaryImages}  translate={t}/>
          </div>
        </section>
        <section className="contracts-section">
            <h1> {t('HEADINGs.CONTRACTS')} </h1>
            <CarouselList items={contractImages}/>
        </section>
        <section className="equpments-section">
          <Slide top>  <h1>{t('HEADINGs.EQUPMENT')}</h1> </Slide>
          <div className="container">
             <EqumentItem equpments={equmentImages} translate={t}/>
          </div>
        </section>
      </div>
    </LayOut>
  );
};

export default Home;
