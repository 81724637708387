import React from "react";
import LayOut from "./../components/layout/index";
import { Link } from "react-router-dom";
import AboutItem from "./../components/about/AboutItem";
import CampanyAchievment from './../components/about/CampanyAchievment';
import AboutVideo from './../components/about/AboutVideo';
import AboutVission from './../components/about/AboutVission';
import { useTranslation } from 'react-i18next';

const About = () => {
  const {t} = useTranslation();
  return (
    <LayOut>
      <div className="about-page">
        <section className="page-header">
          <div className="container">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/"> {t('NAVBER.HOME')} </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                {t('NAVBER.ABOUTUS')}
                </li>
              </ol>
            </nav>
          </div>
          <div className="overlay"></div>
        </section>
        <section className="about-content mt-5">
          <div className="container">
            <AboutItem
             translate={t}
            />
          </div>
          <CampanyAchievment translate={t}/>
          <AboutVideo  translate={t} />
          <AboutVission  translate={t} />
        </section>
      </div>
    </LayOut>
  );
};

export default About;
