import React from "react";
import { SmileOutlined } from "@ant-design/icons";
import { Timeline } from "antd";

const AboutVission = ({translate}) => {
  return (
    <div className="about-mission-vision">
      <div className="container">
        <section className="header">
          <h2>{translate('HEADINGs.MISSIONHEADING')}</h2>
          <p> {translate('ABOUT.MISSIONDESCRIPTION')} </p>
        </section>
        <section className="timeline-mission-vission">
          <div className="row mt-5">
            <div className="col-md-6 mb-3">
              <Timeline>
                <Timeline.Item color="green">
                  <div className="box">
                    <h3>{translate('ABOUT.MISSION1.TITLE')}</h3>
                    <p>{translate('ABOUT.MISSION1.DES1')}</p>
                    <p>{translate('ABOUT.MISSION1.DES2')}</p>
                    <p>{translate('ABOUT.MISSION1.DES3')}</p>
                  </div>
                </Timeline.Item>
                <Timeline.Item>
                  <div className="box">
                    <h3>{translate('ABOUT.MISSION2.TITLE')}</h3>
                    <p>{translate('ABOUT.MISSION2.DES1')}</p>
                  </div>
                </Timeline.Item>
                <Timeline.Item color="#00CCFF" dot={<SmileOutlined />}>
                  <p>{translate('ABOUT.MISSION&VISSION')}</p>
                </Timeline.Item>
              </Timeline>
            </div>
            <div className="col-md-6 mb-3">
              <Timeline>
                <Timeline.Item color="green">
                  <div className="box">
                    <h3>{translate('ABOUT.MISSION3.TITLE')}</h3>
                    <p>{translate('ABOUT.MISSION3.DES1')}</p>
                  </div>
                </Timeline.Item>
                <Timeline.Item>
                  <div className="box">
                    <h3>{translate('ABOUT.MISSION4.TITLE')}</h3>
                    <p>{translate('ABOUT.MISSION4.DES1')}</p>
                  </div>
                </Timeline.Item>
                <Timeline.Item color="#00CCFF" dot={<SmileOutlined />}>
                  <p>{translate('ABOUT.MISSION&VISSION')}</p>
                </Timeline.Item>
              </Timeline>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AboutVission;
