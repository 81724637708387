import React, { useState,useEffect,createContext } from 'react';
import axios from "axios";
import {AiOutlineHome} from 'react-icons/ai';
import {MdEngineering,MdOutlineHouse,MdHardware,MdSupport,MdOutlineWorkspaces} from 'react-icons/md';
import {SiPaloaltosoftware} from 'react-icons/si';
import {GiChemicalBolt} from 'react-icons/gi';
import {FaRoad,FaCuttlefish} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

export const AppContext = createContext();

export  const ThemeProvider = (props)=> {
    const {t} = useTranslation();
    const [state,setState] = useState({
        loading:true,
        language : localStorage.getItem('i18nextLng'),
        sliderData:[],
        gallaryImages:[],
        equmentImages:[],
        services:[
            {
                id:1,
                icon:<AiOutlineHome/>,
                name:t('SERVICES.SERVICE1'),
            },
            {
                id:2,
                icon:<MdEngineering/>,
                name:t('SERVICES.SERVICE2'),
            },
            {
                id:3,
                icon:<MdHardware/>,
                name:t('SERVICES.SERVICE3'),
            },
            {
                id:4,
                icon:<SiPaloaltosoftware/>,
                name:t('SERVICES.SERVICE4'),
            },            
            {
                id:5,
                icon:<MdOutlineHouse/>,
                name:t('SERVICES.SERVICE5'),
            },
            {
                id:6,
                icon:<FaRoad/>,
                name:t('SERVICES.SERVICE6'),
            },
            {
                id:7,
                icon:<MdSupport/>,
                name:t('SERVICES.SERVICE7'),
            },
            {
                id:8,
                icon:<FaCuttlefish/>,
                name:t('SERVICES.SERVICE8'),
            },
            {
                id:9,
                icon:<GiChemicalBolt/>,
                name:t('SERVICES.SERVICE9'),
            },
            {
                id:10,
                icon:<MdOutlineWorkspaces/>,
                name:t('SERVICES.SERVICE10'),
            }
        ],
        contractImages:[],
        videos:[],
    });
   
    // fetch all data
    useEffect(()=>{
        axios.get("/data/data.json").then(res=>{
            setState((prevState=>({
                ...prevState,
                sliderData:res.data.sliders,
                gallaryImages:res.data.gallaryImages,
                contractImages:res.data.contractImages,
                equmentImages:res.data.equmentImages,
                videos:res.data.videos
        })))
        }).catch(err=>{
            if(err){
                console.log(err)
            }
        })

    },[]);
 
   const changeLangSite = (lang)=>{
    setState((prev=>({
        ...prev,language:lang
    })))
   }
    return (
        <AppContext.Provider value={
            {
                ...state,
                toggleLanguage:changeLangSite
            }
        }>
            {props.children}
        </AppContext.Provider>
    )
    
}
