import React from 'react';
import {AiFillPlayCircle} from "react-icons/ai";
import { Link,useLocation } from 'react-router-dom';
import {Slide} from 'react-reveal';

const AboutItem = ({titel,description,translate}) => {
    const location = useLocation();
  return (
    <div className="about-item">
        <div className="row">
            <div className='col-md-6'>
                <div className="about-vedio">
                    <a className="iframe" href="https://www.youtube.com/watch?v=y6mdnnMtSjk">
                       <div className="about-vedio-icon">
                            <AiFillPlayCircle />
                       </div>
                    </a>
                </div>
            </div>
            <div className='col-md-6'>
                <div className="about-info">
                    <Slide top> <h2> {translate('WELCOME')} {translate('COMPANYNAME')} </h2> </Slide>
                    
                    <p> {translate('ABOUT.DESCRIPTION1')} </p>
                    {
                         location.pathname==='/about'?
                        <p>
                        {translate('ABOUT.DESCRIPTION2')}
                        </p>:null
                    }
                    {
                        location.pathname==='/about'?null: <Slide bottom>
                            <Link to="/about">  {translate('BUTTONS.READMORE')} </Link>
                        </Slide> 
                    }
                    
                </div>
            </div>
        </div>

    </div>
  )
}

export default AboutItem