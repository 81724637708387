import React from 'react';
import {Link,NavLink} from'react-router-dom';
import logo from '../../../asstes/images/logo.jpg';

const HeaderLink = ({label,to,res,click})=>{
  return <li className="nav-item">
  <NavLink className="nav-link" to={to} {...res} onClick={click} > {label} </NavLink>
</li>
};

const Header = ({translate}) => {
  return (
    <div className="nav-header">
      <nav className="navbar navbar-expand-lg bg-body-tertiary fixed-top">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src={logo} alt='log'  />
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav m-auto mb-2 mb-lg-0">
              <HeaderLink to="/" label={translate('NAVBER.HOME')} />
              <HeaderLink to="/about" label={translate('NAVBER.ABOUTUS')}/>
              <HeaderLink to="/projects" label={translate('NAVBER.PROJECTS')}/>
              <HeaderLink to="/contact" label={translate('NAVBER.CONTACTUS')}/>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Header
