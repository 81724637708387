import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };


const CarouselList = ({items}) => {
  return (
    <Carousel 
        responsive={responsive}
        infinite={true}
        autoPlaySpeed={3000}
        autoPlay={true}

    >
    {
        items&&items.map((item)=>{
            return (
                <div className="item-image" key={item.id} >
                    <img src={item.image} alt=""/>
                </div>
            )
        })
    }
    </Carousel>
  )
}

export default CarouselList