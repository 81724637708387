import React, { useState, useEffect } from "react";
import { Fade } from "react-reveal";
const EqumentItem = ({ equpments,translate }) => {
  const [filterEqupments, setEqupments] = useState([]);
  const [category, setCategory] = useState("all");

  //handel useEffect
  useEffect(() => {
    setEqupments(equpments);
  }, [equpments]);
  //fiter equments
  const filterEquments = (category) => {
    if (category === "all") {
      setEqupments(equpments);
      setCategory("all");
    } else {
      const equpmentsx =
        equpments &&
        equpments.filter((item) => {
          return item.category === category;
        });
      setEqupments(equpmentsx);
      setCategory(category);
    }
  };
  return (
    <div className="equment-wrap">
      <ul className="header-tap">
        <li
          className={category === "all" ? "active" : null}
          onClick={() => {
            filterEquments("all");
          }}
        >
          {translate('TAPS.ALL')}
        </li>
        <li
          className={category === "paving" ? "active" : null}
          onClick={() => {
            filterEquments("paving");
          }}
        >
           {translate('TAPS.PAVING')}
        </li>
        <li
          className={category === "holes" ? "active" : null}
          onClick={() => {
            filterEquments("holes");
          }}
        >
          {translate('TAPS.HOLES')}
        </li>
        <li
          className={category === "Transfer" ? "active" : null}
          onClick={() => {
            filterEquments("Transfer");
          }}
        >
           {translate('TAPS.TRANSFER')}
        </li>
        <li
          className={category === "lifting" ? "active" : null}
          onClick={() => {
            filterEquments("lifting");
          }}
        >
           {translate('TAPS.LIFTING')}
        </li>
      </ul>
      <div className="row">
        {filterEqupments &&
          filterEqupments.map((eq) => {
            return (
              <Fade key={eq.id}>
                  <div key={eq.id} className="col-md-3">
                  <div className="equpment-item">
                    <img src={eq.image} alt="equpment" />
                  </div>
                </div>
              </Fade>
                
            );
          })}
      </div>
    </div>
  );
};

export default EqumentItem;
