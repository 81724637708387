import React from "react";
import { AiOutlinePlus } from "react-icons/ai";
import CountUp from 'react-countup';


const CampanyAchievment = ({translate}) => {
  return (
    <div className="company-achievement">
      <div className="overlay">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div>
                <h2>
                  <CountUp delay={2} end={100} /> <AiOutlinePlus />{" "}
                </h2>
                <p> {translate('ABOUT.EMPLOYEED')} </p>
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <h2>
                  <CountUp delay={2} end={50} /> <AiOutlinePlus />{" "}
                </h2>
                <p> {translate('ABOUT.SATISFIEDC')} </p>
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <h2>
                  <CountUp delay={2} end={500} /> <AiOutlinePlus />{" "}
                </h2>
                <p> {translate('ABOUT.AWARDSWON')} </p>
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <h2>
                  <CountUp delay={2} end={1000} /> <AiOutlinePlus />{" "}
                </h2>
                <p> {translate('ABOUT.PROJECTCOMPLETED')} </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampanyAchievment;
