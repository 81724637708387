import {useEffect} from 'react';
import './App.scss';
import  i18n  from 'i18next';
import {BrowserRouter} from 'react-router-dom';
import { RouterApp } from './route/Router';
// import Loading from './components/Loading';

function App() {
  // const [loading,setLoading] = useState(false);
  useEffect(()=>{
    document.documentElement.dir = i18n.dir();
    document.body.className = i18n.dir();
  },[]);
 
    // window.onload = ()=>{
    //   setLoading(true);
    // }
  return (
    <BrowserRouter>
      <div className="App">
      {/* {
        loading?<Loading/>:<RouterApp/>
      } */}
      <RouterApp/>
      </div>
    </BrowserRouter>
    
  );
};
export default App;
