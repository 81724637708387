import React from "react";
import LayOut from "./../components/layout/index";
import { Link } from "react-router-dom";
import { Slide } from "react-reveal";
import { useTranslation } from "react-i18next";
import alrashidLogo from '../asstes/images/alrashid.jpg';
import nasLogo from '../asstes/images/nas.jpg';
import catLogo from '../asstes/images/cat.jpg';

const Projects = () => {
  const { t } = useTranslation();
  return (
    <LayOut>
      <div className="projects">
        <section className="page-header">
          <div className="container">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/"> {t("NAVBER.HOME")} </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {t("NAVBER.PROJECTS")}
                </li>
              </ol>
            </nav>
          </div>
          <div className="overlay"></div>
        </section>
        <section className="projects-content">
          <div className="container">
            <div className="header">
              <Slide top>
                {" "}
                <h2> {t("HEADINGs.PROJECTSHEADING")} </h2>{" "}
              </Slide>
              <Slide right>
                <p> {t("PROJECTS.DESCRIPTION")} </p>
              </Slide>
            </div>
            <div className="projects">
              <div className="row">
                {/* project 1*/}
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Excavation && backfilling</h5>
                      <p className="client d-flex justify-content-between">
                        {" "}
                        <div>
                          <strong>client:</strong>{" "}
                          <span>Al Rashid Trading and Contracting Company</span>{" "}
                        </div>
                        <img src={alrashidLogo} alt="alrashid logo" />
                      </p>
                      <ul className="card-text">
                        <li>
                          <strong>Excavation(quantified)</strong>
                          <p>
                            normal Excavation work as per project
                            specifictions,and disposal of Excavated materials as
                            per site instruction .
                          </p>
                        </li>
                        <li>
                          <strong>backfilling(quantified)</strong>
                          <p>
                            supply & backfilling works as per project
                            specifictions .
                          </p>
                        </li>
                        <li>
                          <strong>backfilling(quantified)</strong>
                          <p>
                            rock Excavation work as per project
                            specifictions,and disposal of Excavated materials as
                            per site instruction .
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* project 2 */}
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Excavation Channels</h5>
                      <p className="client d-flex justify-content-between">
                        {" "}
                       <div>
                       <strong>client:</strong> <span>NAS Company</span>{" "}
                       </div>
                       <img src={nasLogo} alt="naslogo" />
                      </p>
                      <ul className="card-text">
                        <li>
                          <strong>backfilling-supply and apply</strong>
                          <p>
                            - subgrade material source: from the borrow pit
                            specified by the contractor . <br />
                            it is the LESSOR'S responsibility to check, verify,
                            test & use approved materials & in case the
                            materials is not suitable consider improvement from
                            the borrow pit materials . <br />- maximum Excavated
                            depth in the borrow pit:02 meters .
                          </p>
                        </li>
                        <li>
                          <strong>
                            Excavation of channels, compaction & stock piling
                          </strong>
                          <p>
                            Excavation of unclassified soils including rocks for
                            opened trapezoidal channels with side slope of 2
                            horizontal and 1 vertical .
                          </p>
                        </li>
                        <li>
                          <strong>backfilling(quantified)</strong>
                          <p>
                            rock Excavation work as per project
                            specifictions,and disposal of Excavated materials as
                            per site instruction .<br />
                            the rate includes formation of slope embankment
                            according to elevation section,compaction and
                            levelling,shoring,transport and stockpile of all
                            Excavated materials .<br />
                            storm channels bottom width varies from 3 to 105
                            meters .<br />
                            compaction required:90% maximum density .
                          </p>
                        </li>
                        <li>
                          <strong>
                            backfilling-supply and apply - from storm channels
                          </strong>
                          <p>
                            backfilling material source: from the storm channel
                            specified by the contractor .<br />
                            backfilling material specifictions:AASHTO M 145
                            CLASS A-2-4 .
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* project 3 */}
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Grubbing And Cleaning</h5>
                      <p className="client d-flex justify-content-between">
                        {" "}
                        <div>
                          <strong>client:</strong> <span>ANAS Company</span>{" "}
                        </div>
                        <img src={nasLogo} alt="naslogo" />
                      </p>
                      <ul className="card-text">
                        <li>
                          <strong>1- grubbing and cleaning </strong>
                          <ul>
                            <li>
                              <p>
                                - Removing loose rock, large stone
                                trees,shrubs,grass and other vegetations, debris
                                total depth as required and in according with
                                the specifictions provided in the task order
                                pertaining to the project for which the services
                                shall be performed .<br />
                                - transporting and dumping the above within the
                                site .<br />
                                - moisture to provide the optimum moisture
                                content .
                                <br />- compact using heavy equipment to achieve
                                90% maximum density .
                              </p>
                            </li>
                            <li>
                              <strong>2- other </strong>
                              <p>
                                - lessor shall arrange PCR test for all
                                personnel/operators prior to mobilize to site .{" "}
                                <br />
                                - lessor shall arrange all HSE requirements
                                including 3rd party certificates for equipment &
                                operators prior mobilize to site .<br />- lessor
                                shall carry out dust control for areas of work
                                including access roads being used for transport
                                of material. water and water tankers shall be
                                provided by LESSOR. the contractor will provide
                                water once the contractor's water well in the
                                relevant site is operational .
                              </p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* project 4 */}
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">supply water tankers</h5>
                      <p className="client d-flex justify-content-between">
                        {" "}
                        <div>
                          <strong>client:</strong> <span>CAT Company</span>{" "}
                        </div>
                        <img src={catLogo} alt="cat logo" />
                      </p>
                      <ul className="card-text">
                        <li>
                          <p>
                            supply of T. unit with water tankers trailer
                            (32,000L) and HD driver with full PWAS and IVMS
                            system .
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* project 5 */}
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Excavation Channels</h5>
                      <p className="client d-flex justify-content-between">
                        {" "}
                        <div>
                        <strong>client:</strong> <span>Al Yamama Company</span>{" "}
                        </div>
                      </p>
                      <ul className="card-text">
                        <li>
                          <strong>backfilling-supply and apply</strong>
                          <p>
                            - subgrade material source: from the borrow pit
                            specified by the contractor . <br />
                            it is the LESSOR'S responsibility to check, verify,
                            test & use approved materials & in case the
                            materials is not suitable consider improvement from
                            the borrow pit materials . <br />- maximum Excavated
                            depth in the borrow pit:02 meters .
                          </p>
                        </li>
                        <li>
                          <strong>
                            Excavation of channels, compaction & stock piling
                          </strong>
                          <p>
                            Excavation of unclassified soils including rocks for
                            opened trapezoidal channels with side slope of 2
                            horizontal and 1 vertical .
                          </p>
                        </li>
                        <li>
                          <strong>backfilling(quantified)</strong>
                          <p>
                            rock Excavation work as per project
                            specifictions,and disposal of Excavated materials as
                            per site instruction .<br />
                            the rate includes formation of slope embankment
                            according to elevation section,compaction and
                            levelling,shoring,transport and stockpile of all
                            Excavated materials .<br />
                            storm channels bottom width varies from 3 to 105
                            meters .<br />
                            compaction required:90% maximum density .
                          </p>
                        </li>
                        <li>
                          <strong>
                            backfilling-supply and apply - from storm channels
                          </strong>
                          <p>
                            backfilling material source: from the storm channel
                            specified by the contractor .<br />
                            backfilling material specifictions:AASHTO M 145
                            CLASS A-2-4 .
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </LayOut>
  );
};

export default Projects;
