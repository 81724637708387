import React from 'react';
import {Routes,Route} from 'react-router-dom';
import Home from './../pages/Home';
import About from './../pages/About';
import Services from './../pages/Services';
import Projects from './../pages/Projects';
import Contact from './../pages/Contact';

export const RouterApp = () => {
  return (
    <Routes>
        <Route path="/" exact element={<Home/>}/>
        <Route path="/about" exact element={<About/>}/>
        <Route path="/services" exact element={<Services/>}/>
        <Route path="/projects" exact element={<Projects/>}/>
        <Route path="/contact" exact element={<Contact/>}/>
    </Routes>
  )
}
