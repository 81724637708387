import React from 'react'
import Header from './navber/Header';
import { useTranslation } from 'react-i18next';
import Footer from './Footer';

const LayOut = (props) => {
  const {t} = useTranslation();
  return (
    <div className="app-layout">
        <Header translate={t} />
        <section className='app-content'>
            {props.children}
        </section>
        <Footer/>
    </div>
  )
}

export default LayOut
