import React from "react";
import LayOut from "./../components/layout/index";
import { Link } from "react-router-dom";
import {MdPlace,MdPhone,MdEmail} from 'react-icons/md';
import { Slide } from 'react-reveal';
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const {t} = useTranslation();
  return (
    <LayOut>
      <div className="contactus">
        <section className="page-header">
          <div className="container">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/"> {t('NAVBER.HOME')} </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                {t('NAVBER.CONTACTUS')}
                </li>
              </ol>
            </nav>
          </div>
          <div className="overlay"></div>
        </section>
        <div className="container">
          <section className="contact-content">
            <div className="section-header">
              <Slide top> <h1> {t('NAVBER.CONTACTUS')} </h1> </Slide>
              <Slide right>
              <p> {t('CONTACT.DESCRIPTION')} </p>
              </Slide>
            </div>
            <div className="row my-5">
              <div className="col-md-6">
                <div className="contact-info">
                  <h3> {t('HEADINGs.CONTACTINFO')} </h3>
                  <ul>
                    <li> <MdPlace/> 
                      <span>
                      {t('ADDRESS.STREETNAME')}
                        </span>  
                    </li>
                    <li> <MdPhone/> <span> 0503922991 / 0553922991 </span> </li>
                    <li> <MdEmail/> <span>info@rosengineering.org</span> </li>
                    <li> <strong>{t('ADDRESS.CITY')}:</strong> <span>{t('ADDRESS.CITYNAME')} .</span> </li>
                    <li> <strong>{t('ADDRESS.DISTRICT')}:</strong> <span>{t('ADDRESS.DISTRICTNAME')} .</span> </li>
                    <li> <strong>{t('ADDRESS.ZIPCODE')}:</strong> <span>36421 </span> </li>
                    <li> <strong>{t('ADDRESS.ZIPCODE')}:</strong> <span>4629 </span> </li>
                    <li> <strong>{t('ADDRESS.ADDITIONALNO')}:</strong> <span>65 </span> </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="map">
                  <div className="mapouter"><div className="gmap_canvas">
                    <iframe title='map' id="gmap_canvas" src="https://maps.google.com/maps?q=Al%20Hufuf%20Al%20Salmaniyah%20King%20Salman%20Bin%20Abdul%20Aziz%20Street&t=&z=13&ie=UTF8&iwloc=&output=embed"  scrolling="no"></iframe>
                    
                    </div></div>
                  </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </LayOut>
  );
};

export default Contact;
